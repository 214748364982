import { IUser } from "../../../model/user.model";
import { EACTIONS } from "../../action.enum";
import { IUserAction } from "./user.action";

export function setUserAction(user: IUser): IUserAction {
  return {
    type: EACTIONS.SET_USER,
    payload: user,
  };
}

export function removeUserAction(): IUserAction {
  return {
    type: EACTIONS.REMOVE_USER,
    payload: null,
  };
}
