export const mockedPriceAlert = {
  priceAlert: [
    {
      id: "3042074225671145049",
      supplierId: "2282861032177140998",
      title: "KYCKLFILE USK 160G+2,5KG",
      supplierArticleId: "450411",
      latest: {
        id: "3087683784485635429",
        itemId: "100000",
        articleId: "3042074225671145049",
        invoiceId: "3087683767347709411",
        dealId: "3026963467845239956",
        supplierId: "2282861032177140998",
        itemDiff: {
          amount: "11.15",
          currency: "SEK",
        },
        quantity: "2.0",
        sumDiff: {
          amount: "22.30",
          currency: "SEK",
        },
        billedPrice: {
          amount: "451.15",
          currency: "SEK",
        },
        invoiceDate: "2023-04-23",
        invoiceNumber: "11167492",
        status: "UNRESOLVED",
        resolutionComment: null,
      },
      content: [
        {
          id: "3042074265953240388",
          itemId: "3042074242104428193",
          articleId: "2783318499911534583",
          invoiceId: "6661",
          dealId: "3027115021244040349",
          supplierId: "2283478909880108310",
          itemDiff: {
            amount: "11.15",
            currency: "SEK",
          },
          quantity: "2.0",
          sumDiff: {
            amount: "902.3",
            currency: "SEK",
          },
          billedPrice: {
            amount: "451.15",
            currency: "SEK",
          },
          invoiceDate: "2023-02-19",
          invoiceNumber: "1000",
          status: "UNRESOLVED",
          resolutionComment: null,
        },
      ],
      total: 1,
      unresolved: 1,
      resolved: 0,
      ignored: 0,
    },
    {
      id: "2498834552747721908",
      supplierId: "2318371232984074022",
      title: "Tomat MENIGO FOG",
      supplierArticleId: "722747",
      latest: {
        id: "3042074265902908737",
        itemId: "2200000",
        articleId: "3042074226040243803",
        invoiceId: "6662",
        dealId: "3032945299736757653",
        supplierId: "2318371232984074022",
        itemDiff: {
          amount: "0.68",
          currency: "SEK",
        },
        quantity: "18",
        sumDiff: {
          amount: "12.24",
          currency: "SEK",
        },
        billedPrice: {
          amount: "14.81",
          currency: "SEK",
        },
        invoiceDate: "2023-02-19",
        invoiceNumber: "2000",
        status: "UNRESOLVED",
        resolutionComment: null,
      },
      content: [
        {
          id: "3042074265953240388",
          itemId: "3042074242104428193",
          articleId: "2783318499911534583",
          invoiceId: "6662",
          dealId: "3027115021244040349",
          supplierId: "2283478909880108310",
          itemDiff: {
            amount: ".68",
            currency: "SEK",
          },
          quantity: "18.0",
          sumDiff: {
            amount: "12.24",
            currency: "SEK",
          },
          billedPrice: {
            amount: "14.81",
            currency: "SEK",
          },
          invoiceDate: "2023-02-19",
          invoiceNumber: "11164355",
          status: "UNRESOLVED",
          resolutionComment: null,
        },
      ],
      total: 3,
      unresolved: 1,
      resolved: 1,
      ignored: 1,
    },
  ],
  total: "2",
};
