import { INewNegotiatedAction } from "../../action/new-negotiated/new-negotiated.action";
import { EACTIONS } from "../../action.enum";

export function reducer(
  state: Array<string> | [],
  action: INewNegotiatedAction
) {
  switch (action.type) {
    case EACTIONS.SET_NEWEST_NEGOTIATED_DEAL_ID:
      return [...state].concat(action.payload);
    case EACTIONS.REMOVE_NEWEST_NEGOTIATED_DEAL_ID:
      return [];
    default:
      return state ? state : [];
  }
}
