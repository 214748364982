import { EACTIONS } from "../../action.enum";
import { IActivity } from "../../app_store";
import { IActivityAction } from "../../action/activity/user.action";

export function reducer(
  state: IActivity | null | undefined = null,
  action: IActivityAction
) {
  switch (action.type) {
    case EACTIONS.SET_ACTIVITY:
      return action.payload;
    case EACTIONS.REMOVE_ACTIVITY:
      return null;
    default:
      return state ? state : null;
  }
}
