import { ILoadingSuppliersAction } from "../../action/suppliers/suppliers.action";
import { EACTIONS } from "../../action.enum";

export function reducer(
  state: boolean = false,
  action: ILoadingSuppliersAction
) {
  switch (action.type) {
    case EACTIONS.SET_LOADING_SUPPLIERS:
      return action.payload;

    default:
      return state ? state : false;
  }
}
