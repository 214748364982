import {
  AnyAction,
  combineReducers,
  createStore,
  Reducer,
  ReducersMapObject,
} from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "../../storage";
import { IOutlet } from "../model/outlet.model";
import { ISetupUser } from "../model/setup-user.model";
import { ISupplierDetail } from "../model/supplier.model";
import { IUnit } from "../model/unit.model";
import { IUser } from "../model/user.model";
import { IActivity, IReduxState, IUserFlags, IMockedData } from "./app_store";
import { reducer as ActivityReducer } from "./reducer/activity";
import { reducer as UserFlagsReducer } from "./reducer/userFlags";
import { reducer as AllSuppliersReducer } from "./reducer/all-suppliers";
import { reducer as DateRangeReducer } from "./reducer/date-range";
import { reducer as HasSuppliersErrorReducer } from "./reducer/has-supplier-error";
import { reducer as LoadingSupplierReducer } from "./reducer/loading-supplier";
import { reducer as NewNegotiatedDealReducer } from "./reducer/new-negotiated";
import { reducer as NewOutletReducer } from "./reducer/new-outlets";
import { reducer as OutletReducer } from "./reducer/outlets";
import { reducer as ParentOrganizationReducer } from "./reducer/parent-organization";
import { reducer as SelectedOutletReducer } from "./reducer/selected-outlet";
import { reducer as SelectedUnitReducer } from "./reducer/selected-unit";
import { reducer as SetupUserReducer } from "./reducer/setup-user";
import { reducer as SuppliersReducer } from "./reducer/suppliers";
import { reducer as UnitsReducer } from "./reducer/units";
import { reducer as UserReducer } from "./reducer/user";
import { reducer as MockedDataReducer } from "./reducer/mocked-data";

const reducers: ReducersMapObject<IReduxState, AnyAction> = {
  user: UserReducer as Reducer<IUser | null, AnyAction>,
  activity: ActivityReducer as Reducer<IActivity | null, AnyAction>,
  userFlags: UserFlagsReducer as Reducer<IUserFlags | null, AnyAction>,
  selectedUnit: SelectedUnitReducer as Reducer<IUnit | undefined, AnyAction>,
  outlets: OutletReducer as Reducer<Array<IOutlet>, AnyAction>,
  newOutlets: NewOutletReducer as Reducer<Array<IOutlet>, AnyAction>,
  selectedOutlet: SelectedOutletReducer as Reducer<
    IOutlet | undefined,
    AnyAction
  >,
  suppliers: SuppliersReducer as Reducer<Array<ISupplierDetail>, AnyAction>,
  dateRange: DateRangeReducer as Reducer<Types.DateRange, AnyAction>,
  parentOrganization: ParentOrganizationReducer as Reducer<
    Types.SetupParentOrganizationForm,
    AnyAction
  >,
  units: UnitsReducer as Reducer<Array<IUnit>, AnyAction>,
  newestNegotiatedDeal: NewNegotiatedDealReducer as Reducer<
    Array<string>,
    AnyAction
  >,
  hasSupplierError: HasSuppliersErrorReducer as Reducer<boolean, AnyAction>,
  loadingSupplier: LoadingSupplierReducer as Reducer<boolean, AnyAction>,
  allSuppliers: AllSuppliersReducer as Reducer<
    Array<ISupplierDetail>,
    AnyAction
  >,
  setupUser: SetupUserReducer as Reducer<Array<ISetupUser>, AnyAction>,
  mockedData: MockedDataReducer as Reducer<IMockedData | null, AnyAction>,
};

export const main_reducer = combineReducers(reducers);

const persistConfig = {
  key: "root",
  storage,
  // blacklist: [],
};

const persistedReducer = persistReducer(persistConfig, main_reducer);

export const Store = createStore(persistedReducer);
export const persistor = persistStore(Store);
