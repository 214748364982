import { IErrorSuppliersAction } from "../../action/suppliers/suppliers.action";
import { EACTIONS } from "../../action.enum";

export function reducer(state: boolean = false, action: IErrorSuppliersAction) {
  switch (action.type) {
    case EACTIONS.SET_ERROR_SUPPLIERS:
      return action.payload;

    default:
      return state ? state : false;
  }
}
