import { useRouter } from "next/router";
import { EAPP_ROUTE } from "../enums/route.enum";

export const usePublicPage = () => {
  const router = useRouter();
  const pathname = router.pathname;
  let isPublicPage: boolean = true;

  if (
    pathname === EAPP_ROUTE.LOGIN ||
    pathname === EAPP_ROUTE.FORGOT_PASSWORD ||
    pathname === EAPP_ROUTE.SIGNUP ||
    pathname === EAPP_ROUTE.CONFIRM_EMAIL ||
    pathname === EAPP_ROUTE.VERIFY ||
    pathname === EAPP_ROUTE.SHARE_SUBSCRIPTION_PAGE
  ) {
    isPublicPage = true;
  } else {
    isPublicPage = false;
  }

  return isPublicPage;
};
