import { useSelector } from "react-redux";
import { IReduxState } from "../redux/app_store";
import { getToken } from "../service/service-utils/service-utils";

export const useAuthUser = () => {
  const { user } = useSelector((store: IReduxState) => store);
  const hasToken: boolean = getToken() ? true : false;

  const isAuth: boolean = user && hasToken ? true : false;

  return isAuth;
};
