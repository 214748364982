import config from "../utils/config";

export const restApiBaseUrl = config.internalApi;

export const restApiResources = {
  RESOURCE: "<RESOURCE_NAME>",
};

export enum actions {
  INIT_API_REQUEST = "INIT_API_REQUEST",
  END_API_REQUEST = "END_API_REQUEST",
  GET_RESPONSE = "GET_RESPONSE",
  HANDLE_HTTP_ERROR = "HANDLE_HTTP_ERROR",
}

export enum httpMethod {
  GET = "GET",
  POST = "POST",
  PUT = "PUT",
  DELETE = "DELETE",
  PATCH = "PATCH",
}
