import { EACTIONS } from "../../action.enum";
import { IParentOrganizationAction } from "./parent-organization.action";

export function setParentOrganizationAction(
  organization: Types.SetupParentOrganizationForm
): IParentOrganizationAction {
  return {
    type: EACTIONS.SET_PARENT_ORGANIZATION,
    payload: organization,
  };
}

export function removeParentOrganizationAction(): IParentOrganizationAction {
  return {
    type: EACTIONS.REMOVE_PARENT_ORGANIZATION,
    payload: null,
  };
}
