import { IOutlet } from "../../../model/outlet.model";
import { IOutletsAction } from "../../action/outlets/outlets.action";

import { EACTIONS } from "../../action.enum";

export function reducer(state: Array<IOutlet> = [], action: IOutletsAction) {
  switch (action.type) {
    case EACTIONS.SET_OUTLETS:
      return action.payload;
    case EACTIONS.REMOVE_OUTLETS:
      return [];

    default:
      return state ? state : [];
  }
}
