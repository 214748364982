import { EACTIONS } from "../../action.enum";
import { IActivity } from "../../app_store";
import { IActivityAction } from "./user.action";

export function setActivityAction(activity: IActivity): IActivityAction {
  return {
    type: EACTIONS.SET_ACTIVITY,
    payload: activity,
  };
}

export function removeActivityAction(): IActivityAction {
  return {
    type: EACTIONS.REMOVE_ACTIVITY,
    payload: null,
  };
}
