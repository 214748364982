import { ThemeOptions } from "@mui/material";

// Colors

const neutral = {
  100: "#F3F4F6",
  200: "#E5E7EB",
  300: "#D1D5DB",
  400: "#9CA3AF",
  500: "#6B7280",
  600: "#4B5563",
  700: "#374151",
  800: "#1F2937",
  900: "#111827",
};

const background = {
  default: "#F9FAFC",
  paper: "#FFFFFF",
};

const divider = "#E6E8F0";

const primary = {
  main: "#29A776",
  light: "#5BD7A8",
  dark: "#14523A",
  contrastText: "#FFFFFF",
};

const secondary = {
  main: "#D8595B",
  light: "#F8A0B0",
  dark: "#D20F32",
  contrastText: "#FFFFFF",
};

const success = {
  main: "#1BC43C",
  light: "#6FEB88",
  dark: "#14902C",
  contrastText: "#FFFFFF",
};

const info = {
  main: "#F0968E",
  light: "#F6BCB7",
  dark: "#E8584A",
  contrastText: "#FFFFFF",
};

const warning = {
  main: "#FDCC18",
  light: "#FEDF72",
  dark: "#8D6F01",
  contrastText: "#FFFFFF",
};

const error = {
  main: "#FE164A",
  light: "#FF9AAF",
  dark: "#8E011F",
  contrastText: "#FFFFFF",
};

const text = {
  primary: "#222020",
  secondary: "#6B7280",
  disabled: "rgba(55, 65, 81, 0.48)",
};

export const lightThemeOptions: ThemeOptions = {
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: neutral[500],
          color: "#FFFFFF",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          "&.MuiChip-filledDefault": {
            backgroundColor: neutral[200],
            "& .MuiChip-deleteIcon": {
              color: neutral[400],
            },
          },
          "&.MuiChip-outlinedDefault": {
            "& .MuiChip-deleteIcon": {
              color: neutral[300],
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            opacity: 1,
            color: text.secondary,
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: divider,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderColor: divider,
          borderStyle: "solid",
          borderWidth: 1,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderColor: divider,
          borderStyle: "solid",
          borderWidth: 1,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: neutral[500],
        },
        track: {
          backgroundColor: neutral[400],
          opacity: 1,
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `.1rem solid ${divider}`,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: neutral[100],
          ".MuiTableCell-root": {
            color: neutral[700],
          },
        },
      },
    },
  },
  palette: {
    action: {
      active: neutral[500],
      focus: "rgba(55, 65, 81, 0.12)",
      hover: "rgba(55, 65, 81, 0.04)",
      selected: "rgba(55, 65, 81, 0.08)",
      disabledBackground: "rgba(55, 65, 81, 0.12)",
      disabled: "rgba(55, 65, 81, 0.26)",
    },
    background,
    divider,
    error,
    info,
    mode: "light",
    neutral,
    primary,
    secondary,
    success,
    text,
    warning,
  },
  shadows: [
    "none",
    "0 .1rem .1rem rgba(100, 116, 139, 0.06), .0rem .1rem .2rem rgba(100, 116, 139, 0.1)",
    "0 .1rem .2rem rgba(100, 116, 139, 0.12)",
    "0 .1rem .4rem rgba(100, 116, 139, 0.12)",
    "0 .1rem .5rem rgba(100, 116, 139, 0.12)",
    "0 .1rem .6rem rgba(100, 116, 139, 0.12)",
    "0 .2rem .6rem rgba(100, 116, 139, 0.12)",
    "0 .3rem .6rem rgba(100, 116, 139, 0.12)",
    "0 .2rem .4rem rgba(31, 41, 55, 0.06), .0rem .4rem .6rem rgba(100, 116, 139, 0.12)",
    "0 .5rem 1.2rem rgba(100, 116, 139, 0.12)",
    "0 .5rem 1.4rem rgba(100, 116, 139, 0.12)",
    "0 .5rem 1.5rem rgba(100, 116, 139, 0.12)",
    "0 .6rem 1.5rem rgba(100, 116, 139, 0.12)",
    "0 .7rem 1.5rem rgba(100, 116, 139, 0.12)",
    "0 .8rem 1.5rem rgba(100, 116, 139, 0.12)",
    "0 .9rem 1.5rem rgba(100, 116, 139, 0.12)",
    "0 1rem 1.5rem rgba(100, 116, 139, 0.12)",
    "0 1.2rem 2.2rem -.8rem rgba(100, 116, 139, 0.25)",
    "0 1.3rem 2.2rem -.8rem rgba(100, 116, 139, 0.25)",
    "0 1.4rem 2.4rem -.8rem rgba(100, 116, 139, 0.25)",
    "0 1rem 1rem rgba(31, 41, 55, 0.04), .0rem 2rem 2.5rem rgba(31, 41, 55, 0.1)",
    "0 2.5rem 5rem rgba(100, 116, 139, 0.25)",
    "0 2.5rem 5rem rgba(100, 116, 139, 0.25)",
    "0 2.5rem 5rem rgba(100, 116, 139, 0.25)",
    "0 2.5rem 5rem rgba(100, 116, 139, 0.25)",
  ],
};
