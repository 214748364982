import { IOutlet, IOutletRole } from "../model/outlet.model";
import client from "../utils/client";

export default class UnitlessOutletManagementService {
  prefix = "outlet-management";

  getOutlets = (): Promise<
    Types.RequestResponse<Types.IResponseList<IOutlet>>
  > => client.get(`${this.prefix}/unit-less/outlets`);

  assaignSuppliers = (body: Array<Types.AssignSuppliersToOutletItem>) =>
    client.create(`${this.prefix}/unit-less/organization-suppliers-bulk`, body);

  createMultiOrganization = (
    body: Types.CreateMultiOrganizationRequest
  ): Promise<Types.RequestResponse<Array<IOutlet>>> =>
    client.create(`${this.prefix}/unit-less/multiple-organization`, body);

  getOutletRoles = (): Promise<
    Types.RequestResponse<Types.IResponseList<IOutletRole>>
  > => client.get(`${this.prefix}/unit-less/outlet-roles`);

  getOrganizationAvailability = (params: {
    legalEntityNumber: string;
  }): Promise<Types.RequestResponse<Types.IResponseList<null>>> =>
    client.get(`${this.prefix}/unit-less/organization-availability`, params);
}
