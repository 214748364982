import { IDateRangeAction } from "../../action/date-range/date-range.action";

import { EACTIONS } from "../../action.enum";

const initialDate: Types.DateRange = {
  from: "1970-01-01",
  to: "1970-01-02",
  period: "default",
};

export function reducer(
  state: Types.DateRange = initialDate,
  action: IDateRangeAction
) {
  switch (action.type) {
    case EACTIONS.SET_DATE_RANGE:
      return action.payload;
    case EACTIONS.REMOVE_DATE_RANGE:
      return initialDate;

    default:
      return state ? state : initialDate;
  }
}
