import { IParentOrganizationAction } from "../../action/parent-organization/parent-organization.action";

import { EACTIONS } from "../../action.enum";
const initialState: Types.SetupParentOrganizationForm = {
  forwardEmail: "",
  organizationName: "",
  organizationNumber: "",
};
export function reducer(
  state: Types.SetupParentOrganizationForm = initialState,
  action: IParentOrganizationAction
) {
  switch (action.type) {
    case EACTIONS.SET_PARENT_ORGANIZATION:
      return action.payload;
    case EACTIONS.REMOVE_PARENT_ORGANIZATION:
      return initialState;

    default:
      return state ? state : initialState;
  }
}
