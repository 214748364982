import { IUnit } from "../../../model/unit.model";
import { IUnitsAction } from "../../action/units/units.action";

import { EACTIONS } from "../../action.enum";

export function reducer(state: Array<IUnit> = [], action: IUnitsAction) {
  switch (action.type) {
    case EACTIONS.SET_UNITS:
      return action.payload;
    case EACTIONS.REMOVE_UNITS:
      return [];

    default:
      return state ? state : [];
  }
}
