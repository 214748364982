import { createContext, useContext, useState } from "react";

const RouteHistoryContext: any = createContext(null);

export const useRouteHistory: any = () => {
  return useContext(RouteHistoryContext);
};

export const RouteHistoryProvider = ({ children }) => {
  const [history, setHistory] = useState([]);

  return (
    <RouteHistoryContext.Provider value={{ history, setHistory }}>
      {children}
    </RouteHistoryContext.Provider>
  );
};
