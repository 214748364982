export enum EACTIONS {
  SET_USER = "SET_USER",
  REMOVE_USER = "REMOVE_USER",
  SET_ACTIVITY = "SET_ACTIVITY",
  REMOVE_ACTIVITY = "REMOVE_ACTIVITY",
  SET_SELECTED_UNIT = "SET_SELECTED_UNIT",
  SET_OUTLETS = "SET_OUTLETS",
  REMOVE_OUTLETS = "REMOVE_OUTLETS",
  REMOVE_SELECTED_UNIT = "REMOVE_SELECTED_UNIT",
  SET_SELECTED_OUTLET = "SET_SELECTED_OUTLET",
  SET_SUPPLIERS = "SET_SUPPLIERS",
  SET_ERROR_SUPPLIERS = "SET_ERROR_SUPPLIERS",
  SET_DATE_RANGE = "SET_DATE_RANGE",
  REMOVE_SUPPLIERS = "REMOVE_SUPPLIERS",
  REMOVE_DATE_RANGE = "REMOVE_DATE_RANGE",
  SET_PARENT_ORGANIZATION = "SET_PARENT_ORGANIZATION",
  REMOVE_SELECTED_OUTLET = "REMOVE_SELECTED_OUTLET",
  REMOVE_PARENT_ORGANIZATION = "REMOVE_PARENT_ORGANIZATION",
  SET_UNITS = "SET_UNITS",
  REMOVE_UNITS = "REMOVE_UNITS",
  SET_NEWEST_NEGOTIATED_DEAL_ID = "SET_NEWEST_NEGOTIATED_DEAL_ID",
  REMOVE_NEWEST_NEGOTIATED_DEAL_ID = "REMOVE_NEWEST_NEGOTIATED_DEAL_ID",
  SET_NEW_OUTLETS = "SET_NEW_OUTLETS",
  REMOVE_NEW_OUTLETS = "REMOVE_NEW_OUTLETS",
  SET_LOADING_SUPPLIERS = "SET_LOADING_SUPPLIERS",
  SET_ALL_SUPPLIERS = "SET_ALL_SUPPLIERS",
  SET_SETUP_USER = "SET_SETUP_USER",
  REMOVE_SETUP_USER = "REMOVE_SETUP_USER",
  SET_USER_FLAGS = "SET_USER_FLAGS",
  REMOVE_USER_FLAGS = "REMOVE_USER_FLAGS",
  SET_MOCKED_DATA = "SET_MOCKED_DATA",
}
