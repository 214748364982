import { IOutlet } from "../../../model/outlet.model";
import { EACTIONS } from "../../action.enum";
import { ISelectedOutletAction } from "./selected-outlet.action";

export function setSelectedOutletAction(
  outlet: IOutlet
): ISelectedOutletAction {
  return {
    type: EACTIONS.SET_SELECTED_OUTLET,
    payload: outlet,
  };
}
export function removeSelectedOutletAction(): ISelectedOutletAction {
  return {
    type: EACTIONS.REMOVE_SELECTED_OUTLET,
    payload: null,
  };
}
