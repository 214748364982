import { Store } from "../redux/store";
import UnitlessOutletManagementService from "../service/unitless-outlet-management.service";
import { setSelectedUnitAction } from "../redux/action/selected-unit";
import { IUnit } from "../model/unit.model";
import { AppDB } from "../persistence";
import { IOutlet, IOutletRole } from "../model/outlet.model";
import { setOutletsAction } from "../redux/action/outlets";
import { setSelectedOutletAction } from "../redux/action/selected-outlet";

interface IProps {
  setSuccess?: (success: boolean) => void;
  outletId?: string;
}

export const getOutlets = async (props?: IProps) => {
  const unitlessOutletService = new UnitlessOutletManagementService();
  const {
    data,
    hasError,
    error,
  }: Types.RequestResponse<Types.IResponseList<IOutletRole>> =
    await unitlessOutletService.getOutletRoles();

  if (hasError) {
    props && props.setSuccess && props.setSuccess(false);
    throw error;
  }

  const outlets: Array<IOutlet> = data?.rows!.map((outlet) => ({
    ...outlet.outletInformation,
    role: outlet.role,
    mainUnitId: outlet.mainUnitId,
  }))!;
  props && props.setSuccess && props.setSuccess(true);
  if (Store) {
    const userId: string = Store.getState().user?.id!;
    const selectedOutlet = await AppDB.selectedOutlet.get(userId);
    const queryOutlet =
      props &&
      props.outletId &&
      outlets &&
      outlets.filter((i) => i.id === props.outletId)
        ? outlets.filter((i) => i.id === props.outletId)[0]
        : undefined;
    const firstOutlet: IOutlet | undefined = queryOutlet
      ? queryOutlet
      : selectedOutlet &&
        selectedOutlet.outlet &&
        outlets.filter((i) => selectedOutlet.outlet.id === i.id) &&
        outlets.filter((i) => selectedOutlet.outlet.id === i.id)[0]
      ? outlets.filter((i) => selectedOutlet.outlet.id === i.id)[0]
      : outlets && outlets[0];
    const unit: IUnit = {
      id: firstOutlet?.mainUnitId!,
      role: firstOutlet?.role!,
      name: "",
    };

    AppDB.selectedOutlet.put({ id: userId, outlet: firstOutlet! });
    outlets && Store.dispatch(setOutletsAction(outlets));
    firstOutlet && Store.dispatch(setSelectedUnitAction(unit!));
    firstOutlet && Store.dispatch(setSelectedOutletAction(firstOutlet!));
  }
};
