import { useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { EONBOARDING_STATUS } from "../enums/onboarding-status.enum";
import { IUser } from "../model/user.model";
import { setUserAction } from "../redux/action/user";
import { IReduxState } from "../redux/app_store";
import { getOutlets } from "./use-set-outlets";

const useIsOnboarded = (): boolean => {
  const { user } = useSelector((store: IReduxState) => store);

  const isOnboarded: boolean =
    user?.onboardingStatus === EONBOARDING_STATUS.ONBOARDED ? true : false;

  return isOnboarded;
};

const useIsStartedOnboarding = (): boolean => {
  const { user } = useSelector((store: IReduxState) => store);

  const isOnboarded: boolean =
    user?.onboardingStatus === EONBOARDING_STATUS.START_ONBOARDING
      ? true
      : false;

  return isOnboarded;
};

const useIsShareInvoiceEmailOnboarding = (): boolean => {
  const { user } = useSelector((store: IReduxState) => store);

  const isOnboarded: boolean =
    user?.onboardingStatus === EONBOARDING_STATUS.SHARE_INVOICE_EMAIL
      ? true
      : false;

  return isOnboarded;
};

const useIsSignedDocument = (): boolean => {
  const { user } = useSelector((store: IReduxState) => store);

  const isSignedDocument: boolean =
    user?.onboardingStatus === EONBOARDING_STATUS.ESIGN_DOCUMENT_SIGNED
      ? true
      : false;

  return isSignedDocument;
};

const useOnboarding = () => {
  const { user } = useSelector((store: IReduxState) => store);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  return (onboard: EONBOARDING_STATUS) => {
    let newDataUser: IUser | null = user
      ? {
          ...user,
          onboardingStatus: onboard,
        }
      : null;

    newDataUser && dispatch(setUserAction(newDataUser));

    queryClient.invalidateQueries("user");
    getOutlets();
  };
};

export {
  useOnboarding,
  useIsStartedOnboarding,
  useIsOnboarded,
  useIsShareInvoiceEmailOnboarding,
  useIsSignedDocument,
};
