import { Box, Typography } from "@material-ui/core";
import { WarningRounded } from "@material-ui/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import config from "../utils/config";
import SVG, { SVG_ICONS } from "./share/SVG";
interface StagingBannerProps {
  width: any;
}
const DebugBanner = (props: StagingBannerProps) => {
  const NEXT_PUBLIC_appEnv = config.appEnv;
  const currentState = localStorage.getItem("hideDebugBanner")
    ? "none"
    : "block";
  const { t } = useTranslation();
  const [display, setDisplay] = useState<string>(currentState);

  return NEXT_PUBLIC_appEnv !== "production" ? (
    <Box
      width={props.width}
      display={display}
      sx={{ position: "fixed", top: "0" }}
      zIndex="2000"
    >
      <Box
        bgcolor="orange"
        alignItems="center"
        justifyContent="space-between"
        display="flex"
        paddingTop="4px"
        paddingBottom="4px"
      >
        <div></div>
        <Box alignItems="center" justifyContent="center" display="flex">
          <WarningRounded style={{ color: "#212121" }} />
          <Box paddingLeft={1}>
            <Typography style={{ color: "#212121", fontWeight: "bold" }}>
              {t("staging").toUpperCase()}
            </Typography>
          </Box>
        </Box>
        <Box
          onClick={() => {
            localStorage.setItem("hideDebugBanner", "true");
            setDisplay("none");
          }}
        >
          <SVG name={SVG_ICONS.CLOSE} style={{ height: "16px" }} />
        </Box>
      </Box>
    </Box>
  ) : (
    <></>
  );
};

export default DebugBanner;
