import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { ISupplier } from "../model/supplier.model";
import {
  setErrorSuppliersAction,
  setLoadingSuppliersAction,
  setSuppliersAction,
} from "../redux/action/suppliers";
import { IReduxState } from "../redux/app_store";
import OutletManagementServices from "../service/outlet-management.services";
import SupplierManagementService from "../service/supplier-management.service";

export const useSupplier = () => {
  const { selectedUnit, selectedOutlet } = useSelector(
    (store: IReduxState) => store
  );

  const dispatch = useDispatch();
  const supplierMutation = useMutation(
    async (): Promise<ISupplier[]> => {
      const outletManagementService = new OutletManagementServices(
        selectedUnit?.id!
      );
      dispatch(setLoadingSuppliersAction(true));
      const { data, hasError, error }: Types.RequestResponse<ISupplier[]> =
        await outletManagementService.getOutletSuppliers(selectedOutlet?.id!);
      if (hasError) {
        dispatch(setSuppliersAction([]));
        dispatch(setErrorSuppliersAction(true));
        dispatch(setLoadingSuppliersAction(false));
        throw error;
      }
      dispatch(setErrorSuppliersAction(false));
      data && suppliersDetailMutation.mutate(data);
      return data!;
    },
    {
      retry: true,
    }
  );

  const suppliersDetailMutation = useMutation(
    async (suppliers: ISupplier[]) => {
      const supplierManagementService = new SupplierManagementService(
        selectedUnit?.id!
      );
      const supplierIds = suppliers.map((supplier) => supplier.id);

      const params = {
        ids: supplierIds,
        pagination: false,
      };

      const { data, hasError, error } =
        await supplierManagementService.getSuppliers(params);

      if (hasError) {
        dispatch(setSuppliersAction([]));
        dispatch(setErrorSuppliersAction(true));
        dispatch(setLoadingSuppliersAction(false));
        throw error;
      }
      data && dispatch(setSuppliersAction(data));
      dispatch(setErrorSuppliersAction(false));
      dispatch(setLoadingSuppliersAction(false));
      return data;
    },
    {
      retry: true,
    }
  );

  return () => {
    supplierMutation.mutate();
  };
};
