import { ISupplierDetail } from "../../../model/supplier.model";
import { EACTIONS } from "../../action.enum";
import { IAllSuppliersAction } from "./all-suppliers.action";

export function setAllSuppliersAction(
  suppliers: Array<ISupplierDetail>
): IAllSuppliersAction {
  return {
    type: EACTIONS.SET_ALL_SUPPLIERS,
    payload: suppliers,
  };
}
