import { ISupplierDetail } from "../../../model/supplier.model";
import { ISuppliersAction } from "../../action/suppliers/suppliers.action";
import { EACTIONS } from "../../action.enum";

export function reducer(
  state: Array<ISupplierDetail> = [],
  action: ISuppliersAction
) {
  switch (action.type) {
    case EACTIONS.SET_SUPPLIERS:
      return action.payload;
    case EACTIONS.REMOVE_SUPPLIERS:
      return action.payload;

    default:
      return state ? state : [];
  }
}
