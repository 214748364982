import { ISupplierDetail } from "../../../model/supplier.model";
import { IAllSuppliersAction } from "../../action/all-suppliers/all-suppliers.action";
import { EACTIONS } from "../../action.enum";

export function reducer(
  state: Array<ISupplierDetail> = [],
  action: IAllSuppliersAction
) {
  switch (action.type) {
    case EACTIONS.SET_ALL_SUPPLIERS:
      return action.payload;

    default:
      return state ? state : [];
  }
}
