import { IUnit } from "../../../model/unit.model";
import { ISelectedUnitAction } from "../../action/selected-unit/selected-unit.action";

import { EACTIONS } from "../../action.enum";

export function reducer(
  state: IUnit | null = null,
  action: ISelectedUnitAction
) {
  switch (action.type) {
    case EACTIONS.SET_SELECTED_UNIT:
      return action.payload;
    case EACTIONS.REMOVE_SELECTED_UNIT:
      return action.payload;

    default:
      return state ? state : null;
  }
}
