import { IOutlet } from "../../../model/outlet.model";
import { EACTIONS } from "../../action.enum";
import { INewOutletsAction } from "./new-outlets.action";

export function setNewOutletsAction(
  outlets: Array<IOutlet>
): INewOutletsAction {
  return {
    type: EACTIONS.SET_NEW_OUTLETS,
    payload: outlets,
  };
}

export function removeNewOutletsAction(): INewOutletsAction {
  return {
    type: EACTIONS.REMOVE_NEW_OUTLETS,
    payload: [],
  };
}
