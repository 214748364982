import { IOutlet } from "../../../model/outlet.model";
import { ISelectedOutletAction } from "../../action/selected-outlet/selected-outlet.action";

import { EACTIONS } from "../../action.enum";

export function reducer(state: IOutlet | null, action: ISelectedOutletAction) {
  switch (action.type) {
    case EACTIONS.SET_SELECTED_OUTLET:
      return action.payload;
    case EACTIONS.REMOVE_SELECTED_OUTLET:
      return action.payload;

    default:
      return state ? state : null;
  }
}
