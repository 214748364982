import axios, { AxiosRequestConfig } from "axios";
import {
  requestInterceptor,
  responseInterceptor,
} from "../service/service-utils/interceptors-service";

import { objectToQueryString } from "./string.utils";

type RequestType = "GET" | "POST" | "PUT" | "DELETE";

async function request<T, S>(
  url: string,
  params: T,
  method: RequestType,
  headers: Headers = new Headers(),
  cancelToken?: AxiosRequestConfig
): Promise<Types.RequestResponse<S>> {
  let returnPayload: Types.RequestResponse<S> = {
    data: null,
    hasError: false,
    error: null,
    errorData: null,
  };

  const options: AxiosRequestConfig = {
    method,
    headers:
      headers && headers.get("X-UNIT-ID")
        ? { "X-UNIT-ID": headers.get("X-UNIT-ID") }
        : { "Content-Type": "application/json" },
  };
  if (params) {
    if (method === "GET") {
      url += "?" + objectToQueryString(params);
    } else {
      options.data = params;
    }
  }
  if (cancelToken) {
    options.cancelToken = cancelToken.cancelToken;
  }

  try {
    const axiosInstance = axios.create();
    requestInterceptor(axiosInstance);
    responseInterceptor(axiosInstance);

    const response = await axiosInstance("/api/hospitad/" + url, options);

    returnPayload = { data: response.data, error: null, hasError: false };
  } catch (error: any) {
    returnPayload = {
      hasError: true,
      error: error.response?.status,
      errorData: error.response?.data,
      data: null,
    };
  }

  return returnPayload;
}

function get<T, S>(
  url: string,
  params: T | null = null,
  headers?: Headers,
  cancelToken?: AxiosRequestConfig
): Promise<Types.RequestResponse<S>> {
  return request(url, params, "GET", headers!, cancelToken);
}

function create<T, S>(
  url: string,
  params: T,
  headers?: Headers
): Promise<Types.RequestResponse<S>> {
  return request(url, params, "POST", headers!);
}

function update<T, S>(
  url: string,
  params: T,
  headers?: Headers
): Promise<Types.RequestResponse<S>> {
  return request(url, params, "PUT", headers!);
}

function remove<T, S>(
  url: string,
  params: T | null = null,
  headers?: Headers
): Promise<Types.RequestResponse<S>> {
  return request(url, params, "DELETE", headers!);
}

export default {
  get,
  create,
  update,
  remove,
};
