import { IMockedDataAction } from "../../action/mocked-data/mocked-data.action";
import { EACTIONS } from "../../action.enum";
import { IMockedData } from "../../app_store";
import { mockedPriceAlert } from "../../../__mocks__/mocked-price-alert";

const initialDate: IMockedData = {
  priceAlert: mockedPriceAlert,
};

export function reducer(
  state: IMockedData = initialDate,
  action: IMockedDataAction
) {
  switch (action.type) {
    case EACTIONS.SET_MOCKED_DATA:
      return action.payload;

    default:
      return state ? state : initialDate;
  }
}
