import { IUnit } from "../../../model/unit.model";
import { EACTIONS } from "../../action.enum";
import { ISelectedUnitAction } from "./selected-unit.action";

export function setSelectedUnitAction(unit: IUnit): ISelectedUnitAction {
  return {
    type: EACTIONS.SET_SELECTED_UNIT,
    payload: unit,
  };
}

export function removeSelectedUnitAction(): ISelectedUnitAction {
  return {
    type: EACTIONS.REMOVE_SELECTED_UNIT,
    payload: null,
  };
}
