import { IOutlet, TOutletEdit } from "../model/outlet.model";
import {
  IRequestSupplier,
  TCreateRequestSupplier,
} from "../model/request-supplier.model";
import { IOrganizationSupplier, ISupplier } from "../model/supplier.model";
import client from "../utils/client";
import BaseService from "./base.service";

export default class OutletManagementServices extends BaseService {
  prefix = "outlet-management";

  createSingle = (
    body: Types.CreateSingleOutletFormRequestBody
  ): Promise<Types.RequestResponse<null>> =>
    client.create(`${this.prefix}/unit-less/single-outlet`, body, this.headers);

  createMultiOrganization = (request: Types.CreateMultiOrganizationRequest) =>
    client.create(
      `${this.prefix}/unit-less/multiple-organization`,
      request,
      this.headers
    );

  getOutlets = () => client.get(`${this.prefix}/outlets`, null, this.headers);

  getOutletsOfUnit = (
    unitId: String
  ): Promise<Types.RequestResponse<Array<IOutlet>>> =>
    client.get(`${this.prefix}/units/${unitId}/outlets`, null, this.headers);

  getOutletSuppliers = (
    outletId: string
  ): Promise<Types.RequestResponse<Array<IOrganizationSupplier>>> =>
    client.get(
      `${this.prefix}/outlets/${outletId}/suppliers`,
      null,
      this.headers
    );

  assignSuppliers = (
    body: Types.AssignSuppliersToOutletItem[]
  ): Promise<Types.RequestResponse<null>> =>
    client.create(
      `${this.prefix}/organization-suppliers-bulk`,
      body,
      this.headers
    );

  editOutlet = (
    outletId: string,
    body: TOutletEdit
  ): Promise<Types.RequestResponse<any>> =>
    client.update(`${this.prefix}/outlets/${outletId}`, body, this.headers);

  deleteSupplier = (
    organizationId: string,
    supplierId: string
  ): Promise<Types.RequestResponse<null>> =>
    client.remove(
      `${this.prefix}/organizations/${organizationId}/suppliers/${supplierId}`,
      null,
      this.headers
    );

  getOrganizationId = (params: {
    outletIds: Array<string>;
  }): Promise<Types.RequestResponse<Array<IOrganizationSupplier>>> =>
    client.get(`${this.prefix}/organization-suppliers`, params, this.headers);

  requestSupplier = (
    body: TCreateRequestSupplier[]
  ): Promise<Types.RequestResponse<IRequestSupplier>> =>
    client.create(
      `${this.prefix}/organization-supplier-requests-bulk`,
      body,
      this.headers
    );

  getRequestedSuppliers = (
    unitId: string
  ): Promise<Types.RequestResponse<Types.IResponseContentList<ISupplier>>> =>
    client.get(
      `${this.prefix}/organizations/${unitId}/supplier-requests`,
      null,
      this.headers
    );
}
