import { ISupplierDetail } from "../../../model/supplier.model";
import { EACTIONS } from "../../action.enum";
import {
  IErrorSuppliersAction,
  ILoadingSuppliersAction,
  ISuppliersAction,
} from "./suppliers.action";

export function setSuppliersAction(
  suppliers: Array<ISupplierDetail>
): ISuppliersAction {
  return {
    type: EACTIONS.SET_SUPPLIERS,
    payload: suppliers,
  };
}
export function removeSuppliersAction(): ISuppliersAction {
  return {
    type: EACTIONS.SET_ERROR_SUPPLIERS,
    payload: [],
  };
}

export function setErrorSuppliersAction(
  hasError: boolean
): IErrorSuppliersAction {
  return {
    type: EACTIONS.SET_ERROR_SUPPLIERS,
    payload: hasError,
  };
}

export function setLoadingSuppliersAction(
  isLoading: boolean
): ILoadingSuppliersAction {
  return {
    type: EACTIONS.SET_LOADING_SUPPLIERS,
    payload: isLoading,
  };
}
