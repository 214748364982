import { IUser } from "../../../model/user.model";
import { IUserAction } from "../../action/user/user.action";
import { EACTIONS } from "../../action.enum";

export function reducer(
  state: IUser | null | undefined = null,
  action: IUserAction
) {
  switch (action.type) {
    case EACTIONS.SET_USER:
      return action.payload;
    case EACTIONS.REMOVE_USER:
      return null;
    default:
      return state ? state : null;
  }
}
