export enum EROUTE {
  MAIN = "/",
  ARTICLES = "/articles",
  ARTICLES_LIST = "/articles/list",
  INVOICES = "/invoices",
  ACCOUNT = "/account",
  START_ONBOARDING = "/onboarding/organization",
  SETUP_MULTI_ORGANIZATION = "/onboarding/organization/add-outlets",
  SETUP_SUPPLIERS = "/onboarding/suppliers?type=single-outlet",
  SETUP_MULTI_ORGANIZATION_SUPPLIERS = "/onboarding/suppliers?type=multiple-outlets",
  SHARE_INVOICE_EMAIL = "/onboarding/invoice",
  LOGIN = "/login",
  SIGNUP = "/signup",
  VERIFY = "/verify",
  CONFIRM_EMAIL = "/confirm-email",
  VERIFY_REQUEST = "/verify-request",
  RESET_PASSWORD = "/reset-password",
  ONBOARDING_FIRST_OUTLET = "/onboarding/organization/first-outlet",
  ONBOARDING_PARENT_ORGANIZATION = "/onboarding/organization/parent-organization",
  ONBOARDING_ADD_OUTLETS = "/onboarding/organization/add-outlets",
  NEGOTIATED_DEAL = "/articles/prices",
  NEGOTIATED_DEAL_CREATE = "/articles/prices/create",
  DEVIATIONS = "/deviations",
  ESIGN_DOCUMENT_SIGNED = "/onboarding/invoice",
  SETTING_ACCOUNT = "/profile",
  OUTLET = "/outlet",
  CREATE_OUTLET = "/outlet/create",
  SUPPLIER = "/supplier",
  SHARE_SUBSCRIPTION_PAGE = "/share-subscription",
}

export enum EAPP_ROUTE {
  MAIN = "/",
  PRICE_MONITORING = "/price-monitoring/overview",
  PRICE_MONITORING_ARTICLES = "/price-monitoring/articles",
  ARTICLES_VIEW = "/articles/products",
  SOURCING = "/sourcing/overview",
  SOURCING_ARTICLES = "/sourcing/articles",
  SUPPLIERS = "/sourcing/suppliers",
  CATALOG = "/sourcing/articles/search",
  INVOICES_VAULT = "/overview/invoices-vault",
  LOGIN = "/login",
  FORGOT_PASSWORD = "/forgot-password",
  SETTING_ACCOUNT = "/setting/account",
  SETTING_OUTLET = "/setting/outlet",
  START_ONBOARDING = "/onboarding",
  SETUP_ORGANIZATION = "/onboarding/setup-organization",
  SETUP_SUPPLIER = "/onboarding/setup-supplier",
  CHECK_SETUP = "/onboarding/check-setup",
  POWER_OF_ATTORNEY = "/onboarding/power-of-attorney",
  ONBOARDING_ACCOUNT = "/onboarding/account",
  SIT_BACK_AND_RELAX = "/onboarding/sit-back-and-relax",
  SIGNUP = "/signup",
  CONFIRM_EMAIL = "/confirm-email",
  VERIFY = "/verify",
  INVOICES = "/invoices",
  BROWSE_SUPPLIERS = "/sourcing/suppliers/browse-suppliers",
  CONTACT_US = "https://parsly.com/home/#contact",
  SUBSCRIPTION_PAGE = "/subscription",
  SHARE_SUBSCRIPTION_PAGE = "/share-subscription",
}
export const MOBILEAPP_ROUTE = {
  DEVIATION: {
    target: "invoiceId",
    main: "invoices",
    detail: "invoice",
  },
};
