import React, { FC } from "react";
import { useSelector } from "react-redux";
import { IReduxState } from "../redux/app_store";
import config from "../utils/config";
import { Helmet } from "react-helmet";

export const Survicate: FC = () => {
  const { user } = useSelector((state: IReduxState) => state);

  const survicateId = config.survicateId;

  if (!user || !survicateId || survicateId == "") {
    return null;
  }

  return (
    <Helmet>
      <script
        src={`https://survey.survicate.com/workspaces/${survicateId}/web_surveys.js`}
        async
      />
      <script>
        {`
                    (function (opts) {
                        opts.traits = {
                            email: '${user.email}',
                            first_name: '${user.fullName}',
                        };
                    })(window._sva = window._sva || {});
                `}
      </script>
    </Helmet>
  );
};
