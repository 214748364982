import React, { useEffect } from "react";
import { useRouter } from "next/router";
import { useDispatch, useSelector } from "react-redux";
import * as Sentry from "@sentry/node";
import { IReduxState } from "../redux/app_store";
import { useSupplier } from "../hooks/use-suppliers";
import config from "../utils/config";
import { EONBOARDING_STATUS } from "../enums/onboarding-status.enum";
import { getOutlets } from "../hooks/use-set-outlets";
import { useAllSupplier } from "../hooks/use-all-supplier";
import { setUserFlagsAction } from "../redux/action/userFlags";
import { checkImpersonation } from "../utils/impersonation";
import { apiRequestHandler } from "../api/requestHandler";
import { setActivityAction } from "../redux/action/activity";
import jwt_decode from "jwt-decode";
interface UserDataProps {
  children: React.ReactNode;
}
declare global {
  interface Window {
    heap: any;
  }
}

const UserData: React.FC<UserDataProps> = ({ children }) => {
  const { user, selectedOutlet } = useSelector((store: IReduxState) => store);
  const useSupplierList = useSupplier();
  const useAllSupplierList = useAllSupplier();
  const router = useRouter();
  const dispatch = useDispatch();
  //const [ldContent, setLdContent] = React.useState<any>(null);

  //Get all supplier

  useEffect(() => {
    useAllSupplierList();
  }, []);

  //Get selected outlet's suppliers
  useEffect(() => {
    const selectedOutletId = selectedOutlet?.id;
    if (selectedOutletId) {
      useSupplierList();
    }
  }, [selectedOutlet?.id]);

  //Send user data to heap tool
  useEffect(() => {
    const isImpersonated = checkImpersonation();
    if (!isImpersonated) {
      window.heap?.identify(user?.id);
      window.heap?.addUserProperties({
        fullName: user?.fullName,
        onboardingStatus: user?.onboardingStatus,
        email: user?.email,
        selectedOutletName: selectedOutlet?.displayName,
        joinedAt: user?.joinedAt?.substr(0, 10),
      });
    }
  }, [user, selectedOutlet]);

  //Send user data to lunch darkly tool
  /* useEffect(() => {
    let initLd = ld.initialize(config.launchDarklyClientId!, {
      key: user?.id,
      name: user?.fullName,
      email: user?.email,
    });
    setLdContent(initLd);
  }, []); */
  const handleActivity = async () => {
    const data: any = await apiRequestHandler(
      `/api/activity?type=activity`,
      "GET",
      null
    );
    if (data) {
      dispatch(setActivityAction(data));
    }
  };
  useEffect(() => {
    //if (ldContent) {
    /*  const userData: ld.LDUser = {
      key: user?.id,
      name: user?.fullName,
      email: user?.email,
    }; */
    const token = jwt_decode(localStorage.getItem("accessToken")!);

    //ldContent.identify(userData).then((el) => {
    apiRequestHandler(
      `/api/hospitad/billing/subscription`,
      "GET",
      {},
      {
        "x-account-id": token!["https://parsly.com/account_id"],
        "Content-Type": "application/json",
      }
    ).then((res: any) =>
      dispatch(
        setUserFlagsAction({
          stripe: res,
          tier:
            res?.plan === "Annually"
              ? "pro"
              : res?.plan === "Quarterly"
              ? "premium"
              : res?.plan === "Trial"
              ? "trial"
              : "free",
        })
      )
    );
    //});
    handleActivity();
    //}
  }, [user, selectedOutlet, router.asPath]);

  //Sentry tool
  useEffect(() => {
    if (user && config.sentryDsn) Sentry.setUser({ id: user.id.toString() });
  }, [user?.id]);

  //Get outlets
  useEffect(() => {
    if (user && user.onboardingStatus !== EONBOARDING_STATUS.START_ONBOARDING) {
      if (router.query && router.query.outletId) {
        getOutlets({ outletId: router.query.outletId as string });
      } else {
        getOutlets();
      }
    }
  }, [router.query && router.query.outletId]);

  return <>{children}</>;
};
//@ts-ignore
//export default withLDProvider<UserDataProps>(lunchDarklyConfig)(UserData);
export default UserData;
