import Dexie from "dexie";
import { IOutletPersist } from "../model/outlet-persist.model";

class IDB extends Dexie {
  selectedOutlet: Dexie.Table<IOutletPersist, string>;

  constructor() {
    super("I_Database");

    this.version(1)
      .stores({
        selectedOutlet: "id",
      })

      .upgrade(async () => {});
    this.selectedOutlet = this.table("selectedOutlet");
  }
}

interface DBBaseModel {
  id: string;
}

export class DBTable<model extends DBBaseModel> {
  constructor(protected table: Dexie.Table) {}

  add(data: model): Promise<any> {
    return this.table.add(data);
  }
  bulkAdd(data: Array<model>): Promise<void> {
    return this.table.bulkAdd(data);
  }
  update(
    id: DBBaseModel["id"],
    changes: { [k in keyof model]: model[k] }
  ): Promise<any> {
    return this.table.update(id, changes);
  }
  patch(
    id: DBBaseModel["id"],
    changes: { [k in keyof model]: model[k] }
  ): Promise<any> {
    return this.table.put(id, changes);
  }
  put(data: model): Promise<void> {
    return this.table.put(data, data.id);
  }
  bulkPut(data: Array<model>): Promise<void> {
    return this.table.bulkPut(data);
  }
  delete(id: DBBaseModel["id"]): Promise<void> {
    return this.table.delete(id);
  }
  bulkDelete(data: Array<DBBaseModel["id"]>): Promise<void> {
    return this.table.bulkDelete(data);
  }
  clear(): Promise<void> {
    return this.table.clear();
  }

  get(id: DBBaseModel["id"]): Promise<model> {
    return this.table.get(id);
  }
  search(query: { skip: number; limit: number }): Promise<Array<model>> {
    return this.table
      .orderBy("id")
      .reverse()
      .offset(query.skip)
      .limit(query.limit)

      .toArray();
  }
  getAll(): Promise<Array<model>> {
    return this.table.toArray();
  }
}

export class IndexedDB {
  private static idb = new IDB();
  static selectedOutlet = new DBTable<IOutletPersist>(
    IndexedDB.idb.selectedOutlet
  );
}
