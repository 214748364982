import { clearRedux } from "../hooks/use-logout";

export const checkImpersonation = () => {
  if (!localStorage?.getItem("isImpersonating")) {
    const token = localStorage?.getItem("accessToken")
      ? JSON.parse(
          Buffer.from(
            localStorage
              .getItem("accessToken")!
              .replace("Bearer ", "")
              .split(".")[1],
            "base64"
          ).toString()
        )
      : false;
    if (token && !!token["https://parsly.com/impersonated_id"]) {
      localStorage.setItem(
        "isImpersonating",
        token["https://parsly.com/impersonated_id"]
      );
    }
  }
  const impersonationEnds: any = localStorage?.getItem("impersonationEnds");
  if (!!impersonationEnds) {
    if (new Date().toISOString() > impersonationEnds) {
      clearRedux();
      localStorage.removeItem("acceptImpersonation");
      localStorage.removeItem("isImpersonating");
      localStorage.removeItem("impersonationEnds");
      window.location.href = "/api/new-logout";
    }
  }
  return !!localStorage?.getItem("isImpersonating") || false;
};
