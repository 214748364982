interface ISetup {
  endpoint: string;
}

export const setup: ISetup = {
  endpoint: "/api/hospitad/",
};

export const awsConfig = () => ({
  accessKeyId: process.env.AWS_ACCESS_KEY_ID,
  secretAccessKey: process.env.AWS_SECRET_ACCESS_KEY,
  region: process.env.AWS_REGION,
});
export const activityTimeController = {
  lastLogin: 1440,
  lastActivity: 30,
};
