import { ISupplierDetail, ISupplierCategory } from "../model/supplier.model";
import client from "../utils/client";
import BaseService from "./base.service";

export default class SupplierManagementService extends BaseService {
  prefix = "supplier-management";

  getAllSuppliers = (): Promise<Types.RequestResponse<ISupplierDetail[]>> =>
    client.get(`${this.prefix}/suppliers?pagination=false`, null, this.headers);

  getSuppliers = (params: {
    ids?: Array<string>;
    categoryIds?: Array<string>;
    pagination?: boolean;
  }): Promise<Types.RequestResponse<ISupplierDetail[]>> =>
    client.get(`${this.prefix}/suppliers`, params, this.headers);

  getSupplierCategories = (): Promise<
    Types.RequestResponse<Types.IResponseContentList<ISupplierCategory>>
  > => client.get(`${this.prefix}/categories`, null, this.headers);
}
