import { IOutlet } from "../../../model/outlet.model";
import { EACTIONS } from "../../action.enum";
import { IOutletsAction } from "./outlets.action";

export function setOutletsAction(outlets: Array<IOutlet>): IOutletsAction {
  return {
    type: EACTIONS.SET_OUTLETS,
    payload: outlets,
  };
}

export function removeOutletsAction(): IOutletsAction {
  return {
    type: EACTIONS.REMOVE_OUTLETS,
    payload: [],
  };
}
