import { useRouter } from "next/router";
import { EAPP_ROUTE } from "../enums/route.enum";

export const useOnboardingPage = () => {
  const router = useRouter();
  const pathname = router.pathname;
  let isOnboardingPage: boolean = true;

  if (
    pathname === EAPP_ROUTE.START_ONBOARDING ||
    pathname === EAPP_ROUTE.SETUP_ORGANIZATION ||
    pathname === EAPP_ROUTE.ONBOARDING_ACCOUNT ||
    pathname === EAPP_ROUTE.SETUP_SUPPLIER ||
    pathname === EAPP_ROUTE.CHECK_SETUP ||
    pathname === EAPP_ROUTE.POWER_OF_ATTORNEY ||
    pathname === EAPP_ROUTE.SIT_BACK_AND_RELAX
  ) {
    isOnboardingPage = true;
  } else {
    isOnboardingPage = false;
  }

  return isOnboardingPage;
};
