import { request } from "./request";
import { serverRequest } from "./serverRequest";

export async function apiRequestHandler(
  endpoint: string,
  type: "POST" | "GET" | "PUT" | "DELETE" | "PATCH",
  body: any,
  header: Record<string, any> = {}
) {
  return request({
    type,
    endpoint,
    headers: {
      ...header,
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
    },
    body,
  });
}

export async function serverRequestHandler(
  endpoint: string,
  type: "POST" | "GET" | "PUT" | "DELETE" | "PATCH",
  body: any,
  header: Record<string, any> = {}
) {
  return serverRequest({
    type,
    endpoint,
    headers: header,
    body,
  });
}
