import * as Sentry from "@sentry/node";
import * as intl from "../utils/i18n";
import type { AppProps } from "next/app";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "react-query";
import React, { useEffect } from "react";
import { Hydrate } from "react-query/hydration";
import { Integrations as TracingIntegrations } from "@sentry/tracing";
import config from "../utils/config";
import { createTheme } from "../theme";
import { NextRouter, useRouter } from "next/router";
import "nprogress/nprogress.css";
import { Toaster } from "react-hot-toast";
import NextNProgress from "../utils/progress-page";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, Store } from "../redux/store";
import "../style/style.css";
import "intro.js/introjs.css";
import "../style/intro.custom.css";
import DebugBanner from "../components/DebugBanner";
import { ToastProvider } from "react-toast-notifications";
import {
  SettingsConsumer,
  SettingsProvider,
} from "../contexts/settings-context";
import { AppRouter } from "../components/app-router";
import { NextPage } from "next";
import Head from "next/head";
import TawkTo from "../utils/chat";
import { Survicate } from "../components/Survicate";
import { EAPP_ROUTE } from "../enums/route.enum";
import { RouteHistoryProvider } from "../contexts/history-context";

type EnhancedAppProps = AppProps & {
  Component: NextPage;
  pageProps: any;
};

if (config.sentryDsn) {
  Sentry.init({
    dsn: config.sentryDsn,
    environment: config.appEnv,
    enabled: process.env.NODE_ENV !== "development",
    release: process.env.SOURCE_VERSION,
    integrations: [new TracingIntegrations.BrowserTracing()],
    tracesSampleRate: 0.5,
  });
}

const queryClient = new QueryClient();

function ParslyApp(props: EnhancedAppProps) {
  const { Component, pageProps } = props;
  const router: NextRouter = useRouter();

  /*useEffect(() => {
    if (
      config.appEnv === "production" &&
      window.location.protocol !== "https:"
    ) {
      window.location.href = `https://${window.location.host}${window.location.pathname}`;
    }
  }, []);*/

  const widgetId = config.tawktoWidgetIdNew!;

  useEffect(() => {
    if (router.pathname !== EAPP_ROUTE.SHARE_SUBSCRIPTION_PAGE) {
      const tawk = new TawkTo(config.tawktoPropertyId!, widgetId!, 1000);

      tawk.onStatusChange(() => {});
    }
  }, []);

  const getLayout = Component.getLayout ?? ((page) => page);

  useEffect(() => {
    const lang = router.query.lang || localStorage.getItem("lang");

    if (!lang) {
      intl.set(
        navigator.languages ? navigator.languages[0] : navigator.language
      );
    } else {
      localStorage.setItem("lang", lang as string);
      intl.set(lang as string);
    }
  }, [router.query.lang]);
  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>
      <Provider store={Store}>
        <PersistGate loading={null} persistor={persistor}>
          <QueryClientProvider client={queryClient}>
            {/* {!is404Page ? ( */}
            <SettingsProvider>
              <SettingsConsumer>
                {({ settings }) => {
                  return (
                    <ThemeProvider
                      theme={createTheme({
                        direction: settings.direction,
                        responsiveFontSizes: settings.responsiveFontSizes,
                        mode: settings.theme,
                      })}
                    >
                      <ToastProvider>
                        <NextNProgress />
                        <CssBaseline />
                        <Toaster position="top-center" />
                        <Hydrate state={pageProps.dehydratedState}>
                          <RouteHistoryProvider>
                            <DebugBanner width="100%" />
                            <Survicate />
                            <AppRouter>
                              {getLayout(<Component {...pageProps} />)}
                            </AppRouter>
                          </RouteHistoryProvider>
                        </Hydrate>
                      </ToastProvider>
                    </ThemeProvider>
                  );
                }}
              </SettingsConsumer>
            </SettingsProvider>
            {/* ) : (
              <OldThemeProvider theme={theme}>
                <NextNProgress />
                <CssBaseline />
                <Hydrate state={pageProps.dehydratedState}>
                  <DebugBanner width="100%" />
                  <CheckRoute>
                    <Component {...pageProps} />
                  </CheckRoute>
                </Hydrate>
              </OldThemeProvider>
            )}{" "} */}
          </QueryClientProvider>
        </PersistGate>
      </Provider>
    </>
  );
}

export default ParslyApp;
