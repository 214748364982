import { RequestData } from "../global/types/request";
import fetch from "node-fetch";
import { httpMethod } from "./consts";

export async function serverRequest<T>(data: RequestData): Promise<T> {
  /* const urlString = /^https?:\/\//.test(data.endpoint)
    ? data.endpoint
    : `${restApiBaseUrl}/${data.endpoint}`;
  const url = new URL(urlString); */

  const url = data.endpoint;
  const options: any = {
    method: data.type,
    headers: {
      ...data.headers,
    },
  };

  /* if (data.params) {
    Object.keys(data.params).forEach((key) => {
      url.searchParams.append(key, data.params[key]);
    });
  } */

  if (data.type !== httpMethod.GET && data.body) {
    options.body = JSON.stringify(data.body || {});
  }
  const response = await fetch(url, options);

  return await response.json().then((result: any) => {
    return response.ok ? result : { ...result, error: true };
  });
}

/* 
  return await response.text().then((text: string) => {
    try {
      const result: T = JSON.parse(text);

      if (!response.ok) {
        const error: RequestError = {
          status: response.status,
          statusText: response.statusText,
        };

        return { ...error, ...result};
      }

      return result;
    } catch (err) {
      const error: RequestError = {
        status: 0,
        statusText: "JSON Misformat",
      };

      return { ...error, error: true };
    }
  });
}
 */
