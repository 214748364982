import { EACTIONS } from "../../action.enum";
import { IUserFlags } from "../../app_store";
import { IUserFlagsAction } from "./userFlags.action";

export function setUserFlagsAction(userFlags: IUserFlags): IUserFlagsAction {
  return {
    type: EACTIONS.SET_USER_FLAGS,
    payload: userFlags,
  };
}

export function removeUserFlagsAction(): IUserFlagsAction {
  return {
    type: EACTIONS.REMOVE_USER_FLAGS,
    payload: null,
  };
}
