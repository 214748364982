import Axios, { AxiosInstance } from "axios";
import { EAPP_ROUTE } from "../../enums/route.enum";
import { ILogin } from "../../model/login.model";
import AuthService from "../auth.service";
import { clearRedux } from "../../hooks/use-logout.old";
import { getToken, setRequestConfig, setToken } from "./service-utils";

let isRefreshing: boolean = false;
let failedQueue: Array<any> = [];

const processQueue = (error: any, token: string | null = null) => {
  failedQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  failedQueue = [];
};

function responseInterceptor(ax_instance: AxiosInstance) {
  ax_instance.interceptors.response.use(
    (response) => {
      // Return a successful response back to the calling service
      return response;
    },
    async (error) => {
      const config = error.config;
      if (!error.response) {
        return new Promise((resolve, reject) => {
          console.log(resolve);
          reject(error);
        });
      } else if (error.response && error.response.status !== 401) {
        return Promise.reject(error);
      }
      if (config.url === "/api/hospitad/auth/users/login") {
        return Promise.reject(error);
      }
      // Return any error which is not due to authentication back to the calling service
      if (isRefreshing) {
        return new Promise(function (resolve, reject) {
          failedQueue.push({ resolve, reject });
        })
          .then((token) => {
            config.headers["Authorization"] = "Bearer " + token;
            return Axios(config);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      config._retry = true;
      isRefreshing = true;
      const authService = new AuthService();
      try {
        const responseNewToken = await authService.refreshToken();

        if (responseNewToken.hasError) {
          clearRedux();
          setTimeout(() => {
            window.location.replace(EAPP_ROUTE.LOGIN);
          }, 0);

          return;
        } else {
          responseNewToken.data &&
            setToken((responseNewToken.data as ILogin).token);

          processQueue(null, (responseNewToken.data as ILogin)!.token);
          return new Promise((resolve, reject) => {
            Axios.request(config)
              .then((response) => {
                resolve(response);
              })
              .catch((error) => {
                reject(error);
              });
          })
            .catch((err) => {
              processQueue(err, null);
            })
            .finally(() => {
              isRefreshing = false;
            });
        }
      } catch (errorT) {
        isRefreshing = false;
        clearRedux();
        setTimeout(() => {
          window.location.replace(EAPP_ROUTE.LOGIN);
        }, 0);
      }
    }
  );
}

function requestInterceptor(ax_instance: AxiosInstance) {
  ax_instance.interceptors.request.use(
    (config) => {
      const tokens = getToken();
      const { url } = config;
      // config.baseURL = setup.endpoint;

      if (
        !url?.includes("renew-token") &&
        !url?.includes("users/login") &&
        !url?.includes("users/forgot-password") &&
        !url?.includes("/users/signup") &&
        !url?.includes("verify-request") &&
        !url?.includes("verify")
      ) {
        config.headers.Authorization = `Bearer ${tokens}`;
        setRequestConfig({
          headers: { Authorization: `Bearer ${tokens}` },
        });
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
}

export { responseInterceptor, requestInterceptor };
