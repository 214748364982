import { ISetupUser } from "../../../model/setup-user.model";
import { EACTIONS } from "../../action.enum";
import { ISetupUserAction } from "./setup-user.action";

export function setSetupUserAction(
  outlets: Array<ISetupUser>
): ISetupUserAction {
  return {
    type: EACTIONS.SET_SETUP_USER,
    payload: outlets,
  };
}

export function removeSetupUserAction(): ISetupUserAction {
  return {
    type: EACTIONS.REMOVE_SETUP_USER,
    payload: [],
  };
}
