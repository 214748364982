import { GridCellValue } from "@mui/x-data-grid";

export const hashCode = (s: string) =>
  s.split("").reduce((a, b) => {
    a = (a << 5) - a + b.charCodeAt(0);
    return a && a > 0 ? a & a : -(a & a);
  }, 0);

export const comparePrice = (p1: GridCellValue, p2: GridCellValue) => {
  if (typeof p1 === "undefined" || typeof p2 === "undefined") {
    return 0;
  }

  return parseFloat(p1 as string) > parseFloat(p2 as string)
    ? 1
    : p1 === p2
    ? 0
    : -1;
};

export function objectToQueryString(obj: any) {
  const newParams = Object.keys(obj).map((key) => {
    if (Array.isArray(obj[key])) {
      let params = "";
      obj[key].forEach((element: any) => {
        if (params) {
          params =
            params +
            "&" +
            encodeURIComponent(key) +
            "=" +
            encodeURIComponent(element);
        } else {
          params = encodeURIComponent(key) + "=" + encodeURIComponent(element);
        }
      });
      return params;
    } else {
      return encodeURIComponent(key) + "=" + encodeURIComponent(obj[key]);
    }
  });
  return newParams.join("&");
}

export function arrayString(data: Array<string> | string): Array<string> {
  if (data) {
    if (Array.isArray(data)) {
      return data;
    } else {
      return [data];
    }
  } else {
    return [];
  }
}
