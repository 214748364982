import { ISetupUser } from "../../../model/setup-user.model";
import { ISetupUserAction } from "../../action/setup-user/setup-user.action";

import { EACTIONS } from "../../action.enum";

export function reducer(
  state: Array<ISetupUser> = [],
  action: ISetupUserAction
) {
  switch (action.type) {
    case EACTIONS.SET_SETUP_USER:
      return action.payload;
    case EACTIONS.REMOVE_SETUP_USER:
      return [];

    default:
      return state ? state : [];
  }
}
