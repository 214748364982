import { EACTIONS } from "../../action.enum";
import { IUserFlags } from "../../app_store";
import { IUserFlagsAction } from "../../action/userFlags/userFlags.action";

export function reducer(
  state: IUserFlags | null | undefined = null,
  action: IUserFlagsAction
) {
  switch (action.type) {
    case EACTIONS.SET_USER_FLAGS:
      return action.payload;
    case EACTIONS.REMOVE_USER_FLAGS:
      return null;
    default:
      return state ? state : null;
  }
}
