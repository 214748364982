import Axios, { CancelTokenSource } from "axios";

function allRequest<T>(requests: (T | Promise<T>)[]) {
  return Axios.all(requests);
}

import { AxiosRequestConfig } from "axios";
import { setup } from "../../config/setup";
const baseUrl = setup.endpoint;

const getToken = () => {
  return localStorage.getItem("accessToken");
};

const setToken = (token: string) => {
  localStorage.setItem("accessToken", token);
};

const removeToken = () => {
  localStorage.removeItem("accessToken");
};

let axiosRequestConfig: AxiosRequestConfig = {
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
};

function setRequestConfig(config: AxiosRequestConfig): AxiosRequestConfig {
  if (config.headers) {
    config.headers = {
      ...axiosRequestConfig.headers,
      ...config.headers,
    };
  }
  axiosRequestConfig = { ...axiosRequestConfig, ...config };

  return { ...axiosRequestConfig, ...config };
}

function cancelTokenSource(): CancelTokenSource {
  return Axios.CancelToken.source();
}

const msgRequestCanceled = "request-canceled";

export {
  getToken,
  setToken,
  removeToken,
  setRequestConfig,
  axiosRequestConfig,
  allRequest,
  cancelTokenSource,
  msgRequestCanceled,
};
