import { useMutation } from "react-query";
import { useDispatch } from "react-redux";
import { ISupplierDetail } from "../model/supplier.model";
import { setAllSuppliersAction } from "../redux/action/all-suppliers";
import SupplierManagementService from "../service/supplier-management.service";

export const useAllSupplier = () => {
  const supplierService = new SupplierManagementService("");
  const dispatch = useDispatch();

  const suppliersQuery = useMutation<ISupplierDetail[]>(
    ["all-suppliers"],
    async () => {
      const {
        data,
        error,
        hasError,
      }: Types.RequestResponse<ISupplierDetail[]> =
        await supplierService.getAllSuppliers();

      if (hasError) {
        throw error;
      }
      data && dispatch(setAllSuppliersAction(data));
      return data!.sort((s1: ISupplierDetail, s2: ISupplierDetail) =>
        s1.displayName < s2.displayName ? -1 : 1
      );
    },
    { retry: 2 }
  );
  return () => {
    suppliersQuery.mutate();
  };
};
